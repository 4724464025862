var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"summarize-post-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey)},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('a-card',[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'enable_daily_digest',
        }
      }}}),(_vm.module.model.params.enable_daily_digest)?_c('nested-content',[_c('time-picker-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'daily_digest_time',
            'clearable': false,
            'time24hr': true,
          }
        }}}),_c('multi-message-editor-with-media-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'daily_digest_message',
            'placeholders': _vm.BASE_PLACEHOLDERS,
            'targetToUpload': _vm.groupUploadTarget,
            'editorMode': _vm.MediaEditorMode.TelegramBase,
            'base-api-url': 'api.chatkeeper.app/cabinet',
            'hasAnchor': true,
            'hasMedia': true,
          },
        }}})],1):_vm._e()],1),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'enable_weekly_digest',
        }
      }}}),(_vm.module.model.params.enable_weekly_digest)?_c('nested-content',[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'weekly_digest_send_to_channel',
          }
        }}}),_c('time-picker-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'weekly_time',
            'clearable': false,
            'time24hr': true,
          }
        }}}),_c('week-day-picker-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': this,
            'key': 'day_of_week',
          }
        }}}),_c('multi-message-editor-with-media-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'weekly_message',
            'placeholders': _vm.BASE_PLACEHOLDERS,
            'targetToUpload': _vm.groupUploadTarget,
            'editorMode': _vm.MediaEditorMode.TelegramBase,
            'base-api-url': 'api.chatkeeper.app/cabinet',
            'hasAnchor': true,
            'hasMedia': true,
          },
        }}})],1):_vm._e()],1),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'enable_monthly_digest',
        }
      }}}),(_vm.module.model.params.enable_monthly_digest)?_c('nested-content',[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'monthly_digest_send_to_channel',
          }
        }}}),_c('time-picker-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'monthly_time',
            'clearable': false,
            'time24hr': true,
          }
        }}}),_c('number-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'day_of_month',
            'min': 1,
            'max': 31
          },
        }}}),_c('multi-message-editor-with-media-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'monthly_message',
            'placeholders': _vm.BASE_PLACEHOLDERS,
            'targetToUpload': _vm.groupUploadTarget,
            'editorMode': _vm.MediaEditorMode.TelegramBase,
            'base-api-url': 'api.chatkeeper.app/cabinet',
            'hasAnchor': true,
            'hasMedia': true,
          },
        }}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }